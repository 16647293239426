import * as React from 'react'

import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout title="Accessibility">
      <h1>Accessibility</h1>
      <p className="usa-intro">
        Accessible and inclusive software is good software.
      </p>
      <p>
        Delivering software for the public means delivering it for <strong>everyone</strong>, across a broad range of abilities. 
        The inverse of the above statement is that inaccessible software is bad software. We know that it is misguided to deliver
        a product and call it "done" if that product does not follow established accessibility standards.
      </p>
      <h2 id="determiningAccessibility">Determining Accessibility</h2>
      <p>
        There are many reference standards and guidelines for accessibility, and establishing accessibility standards for a project should be 
        among the first planning stages. Utilizing resources such as 
        the <a target="_blank" rel="noreferrer" className="usa-link" href='https://www.w3.org/TR/WCAG20/'>Web Content Accessibility Guidelines (WCAG) 2.0</a> or <a href='https://www.w3.org/TR/WCAG21/'>WCAG 2.1 guidelines</a> is an essential first 
        step, but it is also important to identify department specific standards and address 
        accessibility testing as part of a delivery model.
      </p>
      <h3>How does accessibility scale?</h3>
      <p>Accessibility should be built into the system from the very moment the project begins, not added in post-release after complaints are fielded.</p>
      <p>
        Once guidelines have been identified, incorporating automated tooling into code quality checks can ensure that many common implementation mistakes never make it into users' hands. Encouraging the use of manual inspection 
        tools such as the United States Social Security Administration (SSA) built <a target="_blank" rel="noreferrer" className="usa-link" href="https://www.ssa.gov/accessibility/andi/help/install.html">ANDI browser extension</a> can
         both train and reinforce any developer's expertise in accessible software development.
      </p>
      <h3 id="section508">Section 508</h3>
        <p>
          Section 508 compliance is mandatory for almost all projects developed in the public sector (with certain exceptions such as some national security projects). The original text describes the parameters of <strong>what</strong> must be accessible, but not necessarily <strong>how</strong>. As of January 2018, 
          508 compliance is determined by compliance with the <a target="_blank" rel="noreferrer" className="usa-link" href='https://www.w3.org/TR/WCAG20/'>Web Content Accessibility Guidelines (WCAG) 2.0</a> established by the W3C 
          organization. 
        </p>
        <h4>WCAG 2.1 vs WCAG 2.0</h4>
        <p>
          WCAG 2.1 has not yet been formally adopted, but it considers modern technology and trends and can be more useful for development. Conforming to WCAG 2.1 standards also 
          conforms to WCAG 2.0 standards, so aiming for WCAG 2.1 standards has very little downside for new projects.
        </p>
      <h2 id="implementationGuidelines">Implementation Guidelines</h2>
      <p>Besides the technical guidelines set forth in the WCAG guidelines, there are many trusted accessibility tutorials.</p>
      <ul className='usa-link'>
        <li><a target="_blank" rel="noreferrer" className="usa-link" href='https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=133'>How to Meet WCAG (Quick Reference)</a></li>
        <li><a target="_blank" rel="noreferrer" className="usa-link" href='https://web.dev/a11y-tips-for-web-dev/'>Web Dev guide to accessibility (by Google)</a></li>
        <li><a target="_blank" rel="noreferrer" className="usa-link" href='https://developer.mozilla.org/en-US/docs/Web/Accessibility'>MDN Web Docs Accessibility</a></li>
      </ul>
      <h2 id="assistiveTechnology">Assistive Technology</h2>
      <p>
        How does assistive technology, such as a screen reader, know what to announce to users?
        The answer is that these technologies rely on developers marking up their pages with semantic HTML.
      </p>
      <p>
        For each node in the Document Object Model (DOM), the browser determines if the node is semantically "interesting" (through the use of specific html tags and attributes) and adds 
        it to the accessibility tree. When assistive technology, like a screen reader, 
        is providing an alternative UI to the user, it is often doing so by walking this accessibility tree.
      </p>
      <p>
        Without the use of semantic HTML, assistive technology has no markers for how to understand the page, and will be unable to give 
        users an understandable version of its content. Instead, those users would get the equivalent experience of a person without visual impairment 
        reading a notepad text file copy pasted from the browser. Information is likely missing, incorrectly ordered, incorrectly titled, etc.
      </p>
    </Layout>

  )
}

export default IndexPage
